import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
var url = "https://srv.sadam.com.tr/konteyner_takip"

export default new Vuex.Store({
  state: {
    durum : [],
    user : [],
    usersMusait: [],
    users: [],
    konum : [],
    days: [],
    bolgeler: [],
    konteynerlar: [],
    hareketDokumu: [],
    konteynerlarSortId: [],
  },
  mutations: {
    initDurum(state,data){
      state.durum = data;
    },
    user(state, user){
      state.user = user;
    },
    users(state, users){
      state.users = users;
    },
    userDel(state, user_id){
      var index = state.users.findIndex( c => c.user_id == user_id);
      if(index > -1){
        state.users.splice(index, 1)
      }
    },
    userAdd(state, user){
      state.users.push(user);
    },
    usersMusait(state, users){
      state.usersMusait = users;
    },
    bolgeler(state, data){
      state.bolgeler = data;
    },
    bolgeAdd(state, data){
      state.bolgeler.unshift(data);
    },
    bolgeSil(state, id){
      var index = state.bolgeler.findIndex( c => c.bolge_id == id);
      if(index > -1){
        state.bolgeler.splice(index, 1)
      }
    },
    bolgeEdit(state, data){
      var index = state.bolgeler.findIndex( c => c.bolge_id == data.bolge_id);
      if(index > -1){
        state.bolgeler[index] = data;
      }
    },
    konteynerlar(state, data){
      state.konteynerlar = data;
    },
    konteynerlarSortId(state, data){
      state.konteynerlarSortId = data;
    },
    konteynerEdit(state, data){
      var index = state.konteynerlar.findIndex( c => c.kk_id == data.kk_id);
      if(index > -1){
        state.konteynerlar[index] = data;
      }
    },
    HareketDokumu(state, data){
      state.hareketDokumu = data;
    },
    atikAlimSil(state, id){
      var index = state.hareketDokumu.findIndex(c => c.atik_alim_id == id);
      if(index > -1){
        state.hareketDokumu.splice(index, 1);
      }
    },
    konum(state, konum){
      state.konum = konum;
    }
  },
  actions: {
    initDurum(context, data){
      context.commit("initDurum", data);
    },

    userCikis(context){
      localStorage.removeItem("user")
      context.commit("user", null);
      context.commit("initDurum", {
        status : true,
        message : "Sistemden başarıyla çıkış yaptınız."
      });
    },

    userInit(context){
      if(localStorage.getItem("user")){
        const user = JSON.parse( localStorage.getItem("user") );
        axios.post( url + "/User/arama", JSON.stringify({user_id : user.user_id}))
        .then(r => {
          context.commit('user', r.data.result);
        })
      } else {
        context.commit('user', null);
      }   
    },

    userGiris(context, data){
      return axios.post( url + "/User/giris", JSON.stringify({
        user_eposta : data.user_eposta, 
        user_sifre : data.user_sifre
      }) )
      .then(r => {
        if(r.data.status){
          localStorage.setItem("user", JSON.stringify(r.data.result));
          context.commit("user", r.data.result)
        }else{
          //Ekrana bilgi basmak
          context.commit("initDurum", {
            result : r.data.result,
            message : r.data.message
          });
        }
      })
    },

    userAdd(context, data){
      return axios.post( url + "/User/add", JSON.stringify(data)).then(r => {
        console.log(r.data)
        if(r.data.status){
          context.commit("userAdd", r.data.result);
        }
        context.commit("initDurum", {
          status: r.data.status,
          message: r.data.message,
        })
        return r.data;
      })
    },

    userEdit(context, data){
      return axios.post( url + "/User/edit", JSON.stringify(data) )
      .then(r => {
        context.commit("initDurum", {
          status: r.data.status,
          message: r.data.message
        })
        return r.data;
      })
    },

    userDel(context, user_id){
      return axios.post( url + "/User/del", JSON.stringify({user_id : user_id}) )
      .then(r => {
        context.commit("userDel", user_id)
        return r.data;
      })
    },

    users(context, data){
      axios.post( url + "/User/users", JSON.stringify(data) ).then(r => {
        if(r.data.status){
          context.commit("users", r.data.result);
        }
      })
    },

    usersMusait(context){
      axios.get( url + "/User/bostakiUsers" ).then(r => {
        context.commit("usersMusait", r.data.result);
      })
    },

    konum(context, konum){
      context.commit("konum", konum);
    },
    
    bolgeler(context){
      axios.get( url + "/Bolge" ).then(r => {
        if(r.data.status){
          context.commit("bolgeler", r.data.result);
        }else{
          context.commit("bolgeler", []);
        }
      })
    },

    bolgeAdd(context, data){
      return axios.post( url + "/Bolge/add", JSON.stringify(data) ).then(r => {
        if(r.data.status){
          context.commit("bolgeAdd", r.data.result);
        }
        context.commit("initDurum", {
          status: r.data.status,
          message: r.data.message
        })
        return r.data;
      })
    },

    bolgeSil(context, id){
      axios.post( url + "/Bolge/sil", JSON.stringify({bolge_id: id}) ).then(r => {
        if(r.data.status){
          context.commit("bolgeSil", id);
        }
        context.commit("initDurum", {
          status: r.data.status,
          message: r.data.message
        });
      })
    },

    bolgeEdit(context, data){
      return axios.post( url + "/Bolge/edit", JSON.stringify(data) )
      .then(r => {
        if(r.data.status){
          context.commit("bolgeEdit", r.data.result);
        }
        context.commit("initDurum", {
          status: r.data.status,
          message: r.data.message
        })
        return r.data;
      })
    },
    
    bolgeToplayiciKontrol(context, data){
      return axios.post( url + "/Bolge/toplayiciKontrol", JSON.stringify(data) ).then(r => {
        return r.data;
      })
    },

    konteynerlar(context){
      axios.get( url + "/Konteyner" ).then(r => {
        context.commit("konteynerlar", r.data.result);
      })
    },

    konteynerlarSortId(context){
      axios.post( url + "/Konteyner/konteynerlarSortId" ).then(r => {
        context.commit("konteynerlarSortId", r.data.result);
      })
    },

    konteynerEdit(context, data){
        return axios.post( url + "/Konteyner/duzenle", JSON.stringify(data) ).then(r => {
          if(r.data.result){
            context.commit("konteynerEdit", r.data.result);
            return r.data;
          }
        })
    },

    konteynerIsaretle(context, data){
      return axios.post( url + "/Konteyner/isaretle", JSON.stringify(data) ).then(r => {
        if(r.data.result){
          context.commit("konteynerEdit", r.data.result);
          return r.data;
        }
      })
    },

    konteynerTopluEkle(context, sayi){
      return axios.post( url + "/Konteyner/topluEkle", JSON.stringify({sayi: sayi}) ).then(r => {
        return r.data;
      })
    },

    HareketDokumu(context, data){
      axios.post( url + "/AtikAlim/hareketDokumu", JSON.stringify(data)).then(r => {
        if(r.data.status){
          context.commit("HareketDokumu", r.data.result);
        }
      })
    },

    atikAlimSil(context, id){
      axios.post( url + "/AtikAlim/sil", JSON.stringify({id: id}) ).then(r => {
        console.log(r.data);
        if(r.data.status){
          context.commit("atikAlimSil", id);
        }
        context.commit("initDurum", {
          status: r.data.status,
          message: r.data.message
        });
      })
    },

    atikAlimEkle(context, data, headers){
      return axios.post( url + "/AtikAlim/ekle", data, headers).then(r => {
        console.log(r.data)
        return r.data;
      })
    }
  },

  modules: {
  }
})
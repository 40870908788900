import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'

Vue.use(VueRouter)
Vue.use(NProgress)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: "Konteyner Takip Sistemi",
    },
    component: () => import('../components/Dashboard.vue')
  },
  {
    path: '/map',
    name: 'Map',
    meta: {
      title: "Konteyner Takip Sistemi",
    },
    component: () => import('../components/maps.vue')
  },
  {
    path: '/konteynerMap',
    name: 'konteynerMap',
    meta: {
      title: "Konteyner Listesi",
    },
    component: () => import('../components/konteynerMap.vue')
  },
  {
    path: '/gmap',
    name: 'gMap',
    meta: {
      title: "Konteyner Takip Sistemi",
    },
    component: () => import('../components/gmaps.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: "About",
    },
    component: () => import('../views/About.vue')
  },
  {
    path: '/ekle/:firma_id',
    name: 'Ekle',
    meta: {
      title: "Firma Ekle",
    },
    component: () => import('../components/Ekle.vue')
  },
  {
    path: '/firmaForm/:firma_id?',
    name: 'firmaForm',
    meta: {
      title: "Firma Formu",
    },
    component: () => import('../components/FirmaForm.vue')
  },
  {
    path: '/firmaDetay/:firma_id?',
    name: 'firmaDetay',
    meta: {
      title: "Firma Detay",
    },
    component: () => import('../components/firmaDetay.vue')
  },
  {
    path: '/firmalar',
    name: 'firmalar',
    meta: {
      title: "Firmalar",
    },
    component: () => import('../components/Firmalar.vue')
  },
  {
    path: '/kullanicilar',
    name: 'kullanicilar',
    meta: {
      title: "Kullanıcılar",
    },
    component: () => import('../components/Kullanicilar.vue')
  },
  {
    path: '/kullaniciForm/:id?',
    name: 'kullaniciForm',
    meta: {
      title: "Kullanıcı Formu",
    },
    component: () => import('../components/KullaniciForm.vue')
  },
  {
    path: '/kullaniciDetay/:id?',
    name: 'kullaniciDetay',
    meta: {
      title: "Kullanıcı Formu",
    },
    component: () => import('../components/KullaniciDetay.vue')
  },
  {
    path: '/toplanan-atiklar/:id?',
    name: 'toplanan-atiklar',
    meta: {
      title: "Toplanan Atıklar",
    },
    component: () => import('../components/toplananAtiklar.vue'),
  },
  {
    path: '/bel_takip_list/:id?',
    name: 'belTakip',
    meta: {
      title: "Belediye Takip",
    },
    component: () => import('../components/belTakip.vue'),
  },
  {
    path: '/bel_sayfa/:id?',
    name: 'bel_sayfa',
    meta: {
      title: "Belediye",
    },
    component: () => import('../components/belSayfa.vue'),
  },
  {
    path: '/bel_sayfa_form',
    name: 'bel_sayfa_Form',
    meta: {
      title: "Belediye Form",
    },
    component: () => import('../components/belSayfaForm.vue'),
  },
  {
    path: '/belForm/:id?',
    name: 'belForm',
    meta: {
      title: "Belediye Form",
    },
    component: () => import('../components/belForm.vue'),
  },
  {
    path: '/belediyeler',
    name: 'belediyeler',
    meta: {
      title: "Belediyeler",
    },
    component: () => import('../components/belediyeler.vue'),
  },
  {
    path: '/rapor',
    name: 'Rapor',
    meta: {
      title: "Konteyner Takip Sistemi",
    },
    component: () => import('../components/Home.vue')
  },
  {
    path: '/bolgeler',
    name: 'Bolgeler',
    meta: {
      title: "Bölgeler",
    },
    component: () => import('../components/bolgeler.vue')
  },
  {
    path: '/bolgeForm/:id?',
    name: 'BolgeForm',
    meta: {
      title: "Bölge Form",
    },
    component: () => import('../components/bolgeForm.vue')
  },
  {
    path: '/rota',
    name: 'rota',
    meta: {
      title: "Rota",
    },
    component: () => import('../components/Rota.vue')
  },
  {
    path: '/konteynerIsaretle',
    name: 'konteynerIsaretle',
    meta: {
      title: "Konteyner İşaretleme",
    },
    component: () => import('../components/konteynerIsaretle.vue')
  },
  {
    path: '/hareketDokumu',
    name: 'hareketDokumu',
    meta: {
      title: "Hareket Dökümü",
    },
    component: () => import('../components/hareketDokumu.vue')
  },
  {
    path: '/manuelEkleme',
    name: 'manuelEkleme',
    meta: {
      title: "Manuel Ekleme",
    },
    component: () => import('../components/manuelEkleme.vue')
  }
]

const router = new VueRouter({
  NProgress,
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  NProgress.start()
  NProgress.set(0.1)
  next()
})



// NProgresss
router.afterEach(() => {
  setTimeout(() => NProgress.done(), 500)
})

export default router
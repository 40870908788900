<template>
    <div id="login">
        <b-col sm="4" offset-sm="4" class="mt-5 text-left">
            <div class="text-center mb-5">
                <h1 class="h3 font-weight-bold text-uppercase text-primary">Konteyner Takip</h1>
            </div>
            <b-form @submit.prevent="giris">
                <b-form-group
                    id="input-group-1"
                    label="E-Posta:"
                    label-for="input-1">
                    <b-form-input
                    id="input-1"
                    v-model="form.user_eposta"
                    type="email"
                    required
                    placeholder="E-Posta adresinizi girin."
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label="Şifre:" label-for="input-2">
                    <b-form-input
                    id="input-2"
                    v-model="form.user_sifre"
                    required
                    type="password"
                    placeholder="Şifrenizi girin"
                    ></b-form-input>
                </b-form-group>

                <b-button block type="submit" variant="primary" class="float-right">Giriş Yap</b-button>
                <b-button block class="float-left d-none">Şifremi Unuttum</b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data(){
        return{
            form : [],
        }
    },
    methods : {
        giris(){
            var popup = Swal.fire({
                icon: 'info',
                title: 'Lütfen Bekleyin',
                text: 'Sistemden dönüş beklenirken lütfen bekleyin.',
                showConfirmButton: false,
            });
            this.$store.dispatch("userGiris", this.form).then(() => {
                popup.close();
            });
        }
    }
}
</script>
<template>
  <div id="app">
    <b-navbar v-if="user" toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand to="/">Konteyner Takip</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav></b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="user.user_rutbe==1">Toplanmayan: <b>{{toplanmayanKonteyner.length}}</b></b-nav-item>
          <b-nav-item v-if="user.user_rutbe==1">Aktif: <b>{{aktifKonteyner.length + "/" + konteynerlar.length}}</b></b-nav-item>
          <b-nav-item v-if="user.user_eposta=='olgay@dinamikdizayn.net'" @click="konteynerEkle()">Konteyner Ekle</b-nav-item>
          <!-- <b-nav-item v-if="user.user_rutbe==1" to="/konteynerIsaretle">İşaretle</b-nav-item> -->
          <b-nav-item v-if="user.user_rutbe==1" to="/belediyeler">Belediyeler</b-nav-item>
          <b-nav-item v-if="user.user_rutbe==1" to="/hareketDokumu">Hareket Dökümü</b-nav-item>
          <b-nav-item v-if="user.user_rutbe==1" to="/konteynerMap">Liste</b-nav-item>
          <b-nav-item v-if="user.user_rutbe==1" to="/manuelEkleme">Manuel</b-nav-item>
          <b-nav-item href="#" @click="cikis()">Çıkış Yap</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view v-if="user" />
    <login v-if="!user" />
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Login from './components/Login.vue';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default {
  data(){
    return{

    }
  },
  components : {
    Login,
  },
  mounted() {
    this.$store.dispatch("userInit");
    this.$store.dispatch("users");
    this.$store.dispatch("bolgeler");
    this.$store.dispatch("konteynerlar");
    this.$store.dispatch("usersMusait");
    this.$store.dispatch("HareketDokumu");
    this.$store.dispatch("konteynerlarSortId");

    this.konum_al();
  },
  methods : {
    cikis(){
      this.$store.dispatch('userCikis');
    },
    konum_al(){
      navigator.geolocation.getCurrentPosition(pos => {
        this.$store.commit("konum", [pos.coords.latitude, pos.coords.longitude] )
      });
    },
    konteynerEkle(){
      Swal.fire({
        title: 'Konteyner Ekle',
        text: 'Eklenecek konteyner miktarını girin',
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
      }).then((result) => {
        
        if (result.isConfirmed) {
        
          var bekleyin = Swal.fire({
            title: 'Lutfen Bekleyin',
            text: 'Veriler sisteme gonderilirken lutfen bekleyin!',
            icon: 'info',
            showConfirmButton: false,
          });

          this.$store.dispatch("konteynerTopluEkle", result.value).then(r => {
            console.log("sonuc:", r);
            bekleyin.close();
            Swal.fire({
              title: r.status ? 'Tebrikler' : 'Hata Oluştu!',
              text: r.message,
              icon: r.status ? 'success' : 'error',
            })
          }).catch(e => {
            console.log("hata:", e);
            bekleyin.close();
          });

        }

      })      
    }
  },
  computed : {
    durum(){
      return this.$store.state.durum;
    },
    user(){
      return this.$store.state.user;
    },
    konteynerlar(){
      return this.$store.state.konteynerlar;
    },
    aktifKonteyner(){
      return this.konteynerlar.filter(c => c.kk_lat != "");
    },
    toplanmayanKonteyner(){
      return this.aktifKonteyner.filter(c => c.data.sure <= 0);
    }
  },
  watch : {
    durum(a){
      if(a.message){
        Toast.fire({
          icon: a.status ? 'success' : 'error',
          title: a.message,
        }).then(() => {
          this.$store.commit("initDurum", {status: null, message: null});
        });
      }
    },
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }
  .navbar-dark .navbar-nav .nav-link{
    color: #FFF!important;
  }
  .navbar-dark .navbar-brand{
    font-weight: 700;
  }
  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Chartkick from 'vue-chartkick' 
import Chart from 'chart.js';
import VueGoogleCharts from 'vue-google-charts'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/tr';
import Swal from 'sweetalert2';
import * as VueGoogleMaps from "vue2-google-maps";

 

Vue.use(VueGoogleCharts)
Vue.use(Chartkick.use(Chart))
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(DatePicker)
Vue.config.productionTip = false

Chartkick.configure({language: "tr"})
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD4BHMXkE0VdEsUORlFxfrfWZ_FOk0Bzgs",
    libraries: "visualization"
  }
});


Vue.mixin({
  methods: {
    veriGuncelle(deger){
      var bilgi = Swal.fire({
          title: 'Lütfen Bekleyin!',
          text: 'Filtre işlemi sürerken bekleyin!',
          icon: 'info',
          showConfirmButton: false,
          allowOutsideClick: false
      });
      this.$store.commit("days", deger);
      this.$store.dispatch("rapor_home_firma", deger);
      this.$store.dispatch("rapor_home_user", deger);
      this.$store.dispatch("rapor_home_chart", deger);
      this.$store.dispatch("homeMiktarTurGroup", deger);
      this.$store.dispatch("firma_miktar_chart", deger);
      this.$store.dispatch("firma_miktar_tur_group", deger);
      this.$store.dispatch("firmalar", deger);
      this.$store.dispatch("atikAlimlari", deger);
      this.$store.dispatch("users", deger);
      this.$store.dispatch("userFind", deger);
      this.$store.dispatch("belTalepler", deger);
      bilgi.close();
      //this.$router.push({name: 'Dashboard', params:{start: this.range[0], finish: this.range[1], amb: this.amb} }).catch(() => null);
    }
  }
});
Vue.filter("miktar", (v) => {
  return v.float(3);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')